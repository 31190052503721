var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"h1"},[_vm._v("Личные данные")]),_c('ValidationObserver',{ref:"Personal",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return _c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('div',{staticClass:"avatar-box"},[_c('div',{staticClass:"avatar-box__avatar"},[_c('upload-photo',{model:{value:(_vm.userAvatar),callback:function ($$v) {_vm.userAvatar=$$v},expression:"userAvatar"}}),(_vm.isShowSelectRole)?_c('v-select',{attrs:{"options":_vm.roles,"searchable":false,"label":"title","clearable":false},on:{"input":function($event){_vm.isUpdatedRole = true}},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}):_vm._e()],1),_c('div',{staticClass:"avatar-box__desc"},[_c('div',{staticClass:"mb-4"},[_c('label',[_vm._v("Полное Имя")]),_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-xl-4",attrs:{"rules":"required|min:2|max:30","name":"Фамилия","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"state":errors[0] ? false : (valid ? true : null),"placeholder":"Фамилия"},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{staticClass:"col-xl-4",attrs:{"rules":"required|min:2|max:30","name":"Имя","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"state":errors[0] ? false : (valid ? true : null),"placeholder":"Имя"},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{staticClass:"col-xl-4",attrs:{"rules":"min:2","name":"Отчество","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var changed = ref.changed;
return _c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"state":errors[0] ? false : (changed && valid ? true : null),"placeholder":"Отчество"},model:{value:(_vm.user.patroName),callback:function ($$v) {_vm.$set(_vm.user, "patroName", $$v)},expression:"user.patroName"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)]),_c('div',{staticClass:"mb-4"},[_c('label',[_vm._v("Контакты")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4 col-lg-6 mb-4"},[_c('ValidationProvider',{staticClass:"mb",attrs:{"rules":"email","name":"E-mail","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"state":errors[0] ? false : (valid ? true : null),"placeholder":"E-mail","disabled":""},model:{value:(_vm.user.email.address),callback:function ($$v) {_vm.$set(_vm.user.email, "address", $$v)},expression:"user.email.address"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('div',{staticClass:"switcher-item"},[_c('label',{on:{"click":function($event){_vm.user.email.isPublic = !_vm.user.email.isPublic}}},[_vm._v("Показывать e-mail")]),_c('b-form-checkbox',{attrs:{"switch":"","size":"lg"},model:{value:(_vm.user.email.isPublic),callback:function ($$v) {_vm.$set(_vm.user.email, "isPublic", $$v)},expression:"user.email.isPublic"}})],1)],1),_c('div',{staticClass:"col-xl-4 col-lg-6 mb-4"},[_c('ValidationProvider',{staticClass:"mb",attrs:{"rules":"phone|min:7|max:20","name":"Телефон 1","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var changed = ref.changed;
return _c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"state":errors[0] ? false : (changed && valid ? true : null),"placeholder":"Номер телефона 1"},model:{value:(_vm.userPhone1.number),callback:function ($$v) {_vm.$set(_vm.userPhone1, "number", $$v)},expression:"userPhone1.number"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('div',{staticClass:"switcher-item"},[_c('label',{on:{"click":function($event){_vm.userPhone1.isPublic = !_vm.userPhone1.isPublic}}},[_vm._v("Показывать номер телефона 1")]),_c('b-form-checkbox',{attrs:{"switch":"","size":"lg"},model:{value:(_vm.userPhone1.isPublic),callback:function ($$v) {_vm.$set(_vm.userPhone1, "isPublic", $$v)},expression:"userPhone1.isPublic"}})],1)],1),_c('div',{staticClass:"col-xl-4 col-lg-6 mb-4"},[(!_vm.isUserPhone2)?_c('div',{staticClass:"pt-2"},[_c('button',{staticClass:"add-btn",attrs:{"type":"button"},on:{"click":function($event){_vm.isUserPhone2 = true}}},[_c('b-icon',{attrs:{"icon":"plus-circle-fill"}}),_c('span',[_vm._v("Добавить номер телефона")])],1)]):_vm._e(),(_vm.isUserPhone2)?_c('div',{staticClass:"extra-item"},[_c('div',{staticClass:"extra-hide"},[_c('button',{staticClass:"btn-crl",on:{"click":_vm.handleHideUserPhone2}},[_c('b-icon',{attrs:{"icon":"dash-circle-fill"}})],1)]),_c('div',{staticClass:"extra-control"},[_c('ValidationProvider',{staticClass:"mb",attrs:{"rules":"phone|min:7|max:20","name":"Телефон 2","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var changed = ref.changed;
return _c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"state":errors[0] ? false : (changed && valid ? true : null),"placeholder":"Номер телефона 2"},model:{value:(_vm.userPhone2.number),callback:function ($$v) {_vm.$set(_vm.userPhone2, "number", $$v)},expression:"userPhone2.number"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('div',{staticClass:"switcher-item"},[_c('label',{on:{"click":function($event){_vm.userPhone2.isPublic = !_vm.userPhone2.isPublic}}},[_vm._v("Показывать номер телефона 2")]),_c('b-form-checkbox',{attrs:{"switch":"","size":"lg"},model:{value:(_vm.userPhone2.isPublic),callback:function ($$v) {_vm.$set(_vm.userPhone2, "isPublic", $$v)},expression:"userPhone2.isPublic"}})],1)],1)]):_vm._e()])])])])]),_c('div',{staticClass:"pt-2 pb-3 mb-4 underline"},[_c('div',{staticClass:"row mb-4"},[_c('ValidationProvider',{staticClass:"col-xl-10 col-md-12",attrs:{"rules":"min:1|max:400","name":"О себе","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var changed = ref.changed;
return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("О себе")]),_c('div',{staticClass:"textarea-wrapper"},[_c('b-form-textarea',{attrs:{"placeholder":"Краткая информация о себе","state":errors[0] ? false : (changed && valid ? true : null),"maxlength":"400"},model:{value:(_vm.user.description),callback:function ($$v) {_vm.$set(_vm.user, "description", $$v)},expression:"user.description"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")]),(_vm.user.description)?_c('div',{staticClass:"text-count",class:_vm.user.description.length >= 400 ? 'text-danger' : ''},[(_vm.user.description.length >= 400)?_c('span',[_vm._v("макс. ")]):_vm._e(),_vm._v(_vm._s(_vm.user.description.length)+" "),(_vm.user.description.length >= 400)?_c('span',[_vm._v("символов")]):_vm._e()]):_vm._e()],1)])}}],null,true)})],1),_c('ValidationProvider',{staticClass:"mb-4",attrs:{"rules":"","name":"Сфера деятельности","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Сфера деятельности")]),_c('b-form-radio',{attrs:{"name":"companyType","value":"2"},model:{value:(_vm.user.companyType),callback:function ($$v) {_vm.$set(_vm.user, "companyType", $$v)},expression:"user.companyType"}},[_vm._v("Власть")]),_c('b-form-radio',{attrs:{"name":"companyType","value":"1"},model:{value:(_vm.user.companyType),callback:function ($$v) {_vm.$set(_vm.user, "companyType", $$v)},expression:"user.companyType"}},[_vm._v("Бизнес")]),_c('b-form-radio',{attrs:{"name":"companyType","value":"3"},model:{value:(_vm.user.companyType),callback:function ($$v) {_vm.$set(_vm.user, "companyType", $$v)},expression:"user.companyType"}},[_vm._v("Общество")]),_c('b-form-radio',{attrs:{"name":"companyType","value":"0"},model:{value:(_vm.user.companyType),callback:function ($$v) {_vm.$set(_vm.user, "companyType", $$v)},expression:"user.companyType"}},[_vm._v("Другое")]),_c('b-form-invalid-feedback',{attrs:{"state":errors[0] ? false : (valid ? true : null)}},[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)}),_c('div',[_c('label',[_vm._v("Профили в соц сетях (личные):")]),_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-md-4",attrs:{"rules":"min:5|link","name":"Linkedin","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var changed = ref.changed;
return _c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"state":errors[0] ? false : (changed && valid ? true : null),"placeholder":"Ссылка на профиль Linkedin"},model:{value:(_vm.user.linkedin),callback:function ($$v) {_vm.$set(_vm.user, "linkedin", $$v)},expression:"user.linkedin"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{staticClass:"col-md-4",attrs:{"rules":"min:5|link","name":"Ссылка на профиль Facebook","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var changed = ref.changed;
return _c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"state":errors[0] ? false : (changed && valid ? true : null),"placeholder":"Ссылка на профиль Facebook"},model:{value:(_vm.user.facebook),callback:function ($$v) {_vm.$set(_vm.user, "facebook", $$v)},expression:"user.facebook"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)])],1),_c('div',{staticClass:"mb-4"},[_c('label',[_vm._v("Место работы")]),_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-md-4",attrs:{"rules":"min:3|max:40","name":"Название компании","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var changed = ref.changed;
return _c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"state":errors[0] ? false : (changed && valid ? true : null),"placeholder":"Название компании"},model:{value:(_vm.user.companyName),callback:function ($$v) {_vm.$set(_vm.user, "companyName", $$v)},expression:"user.companyName"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{staticClass:"col-md-4",attrs:{"rules":"min:3|max:40","name":"Должность","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var changed = ref.changed;
return _c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"state":errors[0] ? false : (changed && valid ? true : null),"placeholder":"Должность"},model:{value:(_vm.user.companyPosition),callback:function ($$v) {_vm.$set(_vm.user, "companyPosition", $$v)},expression:"user.companyPosition"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{staticClass:"col-md-4",attrs:{"rules":"min:5|link","name":"Сайт компании","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var changed = ref.changed;
return _c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"state":errors[0] ? false : (changed && valid ? true : null),"placeholder":"Сайт компании"},model:{value:(_vm.user.companySite),callback:function ($$v) {_vm.$set(_vm.user, "companySite", $$v)},expression:"user.companySite"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)]),_c('div',{staticClass:"mb-4"},[_c('label',[_vm._v("Контакты")]),_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-md-4",attrs:{"rules":"min:3","name":"Адрес","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var changed = ref.changed;
return _c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"state":errors[0] ? false : (changed && valid ? true : null),"placeholder":"Адрес"},model:{value:(_vm.user.companyAddress),callback:function ($$v) {_vm.$set(_vm.user, "companyAddress", $$v)},expression:"user.companyAddress"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{staticClass:"col-md-4",attrs:{"rules":"email","name":"E-mail компании","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var changed = ref.changed;
return _c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"state":errors[0] ? false : (changed && valid ? true : null),"placeholder":"E-mail компании"},model:{value:(_vm.user.companyEmail[0]),callback:function ($$v) {_vm.$set(_vm.user.companyEmail, 0, $$v)},expression:"user.companyEmail[0]"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)]),_c('div',{staticClass:"row mb-4"},[_c('ValidationProvider',{staticClass:"col-md-4",attrs:{"rules":"phone|min:7|max:20","name":"Номер телефона 1","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var changed = ref.changed;
return _c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"state":errors[0] ? false : (changed && valid ? true : null),"placeholder":"Номер телефона 1"},model:{value:(_vm.user.companyPhone[0]),callback:function ($$v) {_vm.$set(_vm.user.companyPhone, 0, $$v)},expression:"user.companyPhone[0]"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('div',{staticClass:"col-md-4"},[(!_vm.isCompanyPhone2)?_c('div',{staticClass:"pt-2"},[_c('button',{staticClass:"add-btn",attrs:{"type":"button"},on:{"click":function($event){_vm.isCompanyPhone2 = true}}},[_c('b-icon',{attrs:{"icon":"plus-circle-fill"}}),_c('span',[_vm._v("Добавить номер телефона")])],1)]):_c('div',{staticClass:"extra-item"},[_c('div',{staticClass:"extra-hide"},[_c('button',{staticClass:"btn-crl",on:{"click":_vm.handleHideCompanyPhone2}},[_c('b-icon',{attrs:{"icon":"dash-circle-fill"}})],1)]),_c('div',{staticClass:"extra-control"},[_c('ValidationProvider',{attrs:{"rules":"phone|min:7|max:20","name":"Телефон 2","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var changed = ref.changed;
return _c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"state":errors[0] ? false : (changed && valid ? true : null),"placeholder":"Номер телефона 2"},model:{value:(_vm.user.companyPhone[1]),callback:function ($$v) {_vm.$set(_vm.user.companyPhone, 1, $$v)},expression:"user.companyPhone[1]"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)])])],1),_c('div',{staticClass:"mb-4"},[_c('label',[_vm._v("Профили в соц сетях (компания):")]),_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-md-4",attrs:{"rules":"min:5|link","name":"Linkedin","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var changed = ref.changed;
return _c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"state":errors[0] ? false : (changed && valid ? true : null),"placeholder":"Ссылка на профиль Linkedin"},model:{value:(_vm.user.companyLinkedin),callback:function ($$v) {_vm.$set(_vm.user, "companyLinkedin", $$v)},expression:"user.companyLinkedin"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{staticClass:"col-md-4",attrs:{"rules":"min:5|link","name":"Facebook","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var changed = ref.changed;
return _c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"state":errors[0] ? false : (changed && valid ? true : null),"placeholder":"Ссылка на профиль Facebook"},model:{value:(_vm.user.companyFacebook),callback:function ($$v) {_vm.$set(_vm.user, "companyFacebook", $$v)},expression:"user.companyFacebook"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)]),_c('div',[_c('b-button',{attrs:{"type":"submit","variant":"primary submit pl-5 pr-5","disabled":_vm.isBusy}},[_vm._v(" сохранить "),(_vm.isBusy)?_c('b-spinner',{attrs:{"small":"","type":"grow"}}):_vm._e()],1)],1)])}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }