<template>
    <div class="wrapper">
        <div class="h1">Личные данные</div>
        <ValidationObserver ref="Personal">
            <b-form slot-scope="{ validate }" @submit.prevent="handleSubmit">
                <div class="avatar-box">
                    <div class="avatar-box__avatar">
                        <upload-photo v-model="userAvatar"/>
                        <v-select
                            v-if="isShowSelectRole"
                            :options="roles"
                            v-model="role"
                            :searchable="false"
                            label="title"
                            :clearable="false"
                            @input="isUpdatedRole = true"
                        >
                        </v-select>
                    </div>
                    <div class="avatar-box__desc">
                        <div class="mb-4">
                            <label>Полное Имя</label>
                            <div class="row">
                                <ValidationProvider rules="required|min:2|max:30" name="Фамилия" tag="div" class="col-xl-4">
                                    <div class="form-group" slot-scope="{ valid, errors }">
                                        <b-form-input
                                            v-model="user.lastName"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            placeholder="Фамилия"
                                        ></b-form-input>
                                        <b-form-invalid-feedback>
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider rules="required|min:2|max:30" name="Имя" tag="div" class="col-xl-4">
                                    <div class="form-group" slot-scope="{ valid, errors }">
                                        <b-form-input
                                            v-model="user.firstName"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            placeholder="Имя"
                                        ></b-form-input>
                                        <b-form-invalid-feedback>
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider rules="min:2" name="Отчество" tag="div" class="col-xl-4">
                                    <div class="form-group" slot-scope="{ valid, errors, changed }">
                                        <b-form-input
                                            v-model="user.patroName"
                                            :state="errors[0] ? false : (changed && valid ? true : null)"
                                            placeholder="Отчество"
                                        ></b-form-input>
                                        <b-form-invalid-feedback>
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="mb-4">
                            <label>Контакты</label>
                            <div class="row">
                                <div class="col-xl-4 col-lg-6 mb-4">
                                    <ValidationProvider rules="email" name="E-mail" tag="div" class="mb">
                                        <div class="form-group" slot-scope="{ valid, errors }">
                                            <b-form-input
                                                v-model="user.email.address"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                placeholder="E-mail"
                                                disabled
                                            ></b-form-input>
                                            <b-form-invalid-feedback>
                                                {{ errors[0] }}
                                            </b-form-invalid-feedback>
                                        </div>
                                    </ValidationProvider>
                                    <div class="switcher-item">
                                        <label @click="user.email.isPublic = !user.email.isPublic">Показывать e-mail</label>
                                        <b-form-checkbox v-model="user.email.isPublic" switch size="lg"></b-form-checkbox>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-6 mb-4">
                                    <ValidationProvider rules="phone|min:7|max:20" name="Телефон 1" tag="div" class="mb">
                                        <div class="form-group" slot-scope="{ valid, errors, changed }">
                                            <b-form-input
                                                v-model="userPhone1.number"
                                                :state="errors[0] ? false : (changed && valid ? true : null)"
                                                placeholder="Номер телефона 1"
                                            ></b-form-input>
                                            <b-form-invalid-feedback>
                                                {{ errors[0] }}
                                            </b-form-invalid-feedback>
                                        </div>
                                    </ValidationProvider>
                                    <div class="switcher-item">
                                        <label @click="userPhone1.isPublic = !userPhone1.isPublic">Показывать номер телефона 1</label>
                                        <b-form-checkbox v-model="userPhone1.isPublic" switch size="lg"></b-form-checkbox>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-6 mb-4">
                                    <div class="pt-2" v-if="!isUserPhone2">
                                        <button type="button" class="add-btn" @click="isUserPhone2 = true">
                                            <b-icon icon="plus-circle-fill"></b-icon>
                                            <span>Добавить номер телефона</span>
                                        </button>
                                    </div>
                                    <div class="extra-item" v-if="isUserPhone2">
                                        <div class="extra-hide">
                                            <button class="btn-crl" @click="handleHideUserPhone2">
                                                <b-icon icon="dash-circle-fill"></b-icon>
                                            </button>
                                        </div>
                                        <div class="extra-control">
                                            <ValidationProvider rules="phone|min:7|max:20" name="Телефон 2" tag="div" class="mb">
                                                <div class="form-group" slot-scope="{ valid, errors, changed }">
                                                    <b-form-input
                                                        v-model="userPhone2.number"
                                                        :state="errors[0] ? false : (changed && valid ? true : null)"
                                                        placeholder="Номер телефона 2"
                                                    ></b-form-input>
                                                    <b-form-invalid-feedback>
                                                        {{ errors[0] }}
                                                    </b-form-invalid-feedback>
                                                </div>
                                            </ValidationProvider>
                                            <div class="switcher-item">
                                                <label @click="userPhone2.isPublic = !userPhone2.isPublic">Показывать номер телефона 2</label>
                                                <b-form-checkbox v-model="userPhone2.isPublic" switch size="lg"></b-form-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pt-2 pb-3 mb-4 underline">
                    <div class="row mb-4">
                        <ValidationProvider rules="min:1|max:400" name="О себе" tag="div" class="col-xl-10 col-md-12">
                            <div class="form-group" slot-scope="{ valid, errors, changed }">
                                <label>О себе</label>
                                <div class="textarea-wrapper">
                                    <b-form-textarea
                                        v-model="user.description"
                                        placeholder="Краткая информация о себе"
                                        :state="errors[0] ? false : (changed && valid ? true : null)"
                                        maxlength="400"
                                    ></b-form-textarea>
                                    <b-form-invalid-feedback>
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                    <div v-if="user.description" class="text-count" :class="user.description.length >= 400 ? 'text-danger' : ''">
                                        <span v-if="user.description.length >= 400">макс. </span>{{user.description.length}} <span v-if="user.description.length >= 400">символов</span>
                                    </div>
                                </div>
                            </div>
                        </ValidationProvider>
                    </div>

                    <ValidationProvider rules="" name="Сфера деятельности" tag="div" class="mb-4">
                        <div class="form-group" slot-scope="{ valid, errors }">
                            <label>Сфера деятельности</label>
                            <b-form-radio v-model="user.companyType" name="companyType" value="2">Власть</b-form-radio>
                            <b-form-radio v-model="user.companyType" name="companyType" value="1">Бизнес</b-form-radio>
                            <b-form-radio v-model="user.companyType" name="companyType" value="3">Общество</b-form-radio>
                            <b-form-radio v-model="user.companyType" name="companyType" value="0">Другое</b-form-radio>
                            <b-form-invalid-feedback :state="errors[0] ? false : (valid ? true : null)">{{ errors[0] }}</b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>

                    <div>
                        <label>Профили в соц сетях (личные):</label>
                        <div class="row">
                            <ValidationProvider rules="min:5|link" name="Linkedin" tag="div" class="col-md-4">
                                <div class="form-group" slot-scope="{ valid, errors, changed }">
                                    <b-form-input
                                        v-model="user.linkedin"
                                        :state="errors[0] ? false : (changed && valid ? true : null)"
                                        placeholder="Ссылка на профиль Linkedin"
                                    ></b-form-input>
                                    <b-form-invalid-feedback>
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider rules="min:5|link" name="Ссылка на профиль Facebook" tag="div" class="col-md-4">
                                <div class="form-group" slot-scope="{ valid, errors, changed }">
                                    <b-form-input
                                        v-model="user.facebook"
                                        :state="errors[0] ? false : (changed && valid ? true : null)"
                                        placeholder="Ссылка на профиль Facebook"
                                    ></b-form-input>
                                    <b-form-invalid-feedback>
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>

                <div class="mb-4">
                    <label>Место работы</label>
                    <div class="row">
                        <ValidationProvider rules="min:3|max:40" name="Название компании" tag="div" class="col-md-4">
                            <div class="form-group" slot-scope="{ valid, errors, changed }">
                                <b-form-input
                                    v-model="user.companyName"
                                    :state="errors[0] ? false : (changed && valid ? true : null)"
                                    placeholder="Название компании"
                                ></b-form-input>
                                <b-form-invalid-feedback>
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>

                        <ValidationProvider rules="min:3|max:40" name="Должность" tag="div" class="col-md-4">
                            <div class="form-group" slot-scope="{ valid, errors, changed }">
                                <b-form-input
                                    v-model="user.companyPosition"
                                    :state="errors[0] ? false : (changed && valid ? true : null)"
                                    placeholder="Должность"
                                ></b-form-input>
                                <b-form-invalid-feedback>
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>

                        <ValidationProvider rules="min:5|link" name="Сайт компании" tag="div" class="col-md-4">
                            <div class="form-group" slot-scope="{ valid, errors, changed }">
                                <b-form-input
                                    v-model="user.companySite"
                                    :state="errors[0] ? false : (changed && valid ? true : null)"
                                    placeholder="Сайт компании"
                                ></b-form-input>
                                <b-form-invalid-feedback>
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>

                <div class="mb-4">
                    <label>Контакты</label>
                    <div class="row">
                        <ValidationProvider rules="min:3" name="Адрес" tag="div" class="col-md-4">
                            <div class="form-group" slot-scope="{ valid, errors, changed }">
                                <b-form-input
                                    v-model="user.companyAddress"
                                    :state="errors[0] ? false : (changed && valid ? true : null)"
                                    placeholder="Адрес"
                                ></b-form-input>
                                <b-form-invalid-feedback>
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider rules="email" name="E-mail компании" tag="div" class="col-md-4">
                            <div class="form-group" slot-scope="{ valid, errors, changed }">
                                <b-form-input
                                    v-model="user.companyEmail[0]"
                                    :state="errors[0] ? false : (changed && valid ? true : null)"
                                    placeholder="E-mail компании"
                                ></b-form-input>
                                <b-form-invalid-feedback>
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mb-4">
                    <ValidationProvider rules="phone|min:7|max:20" name="Номер телефона 1" tag="div" class="col-md-4">
                        <div class="form-group" slot-scope="{ valid, errors, changed }">
                            <b-form-input
                                v-model="user.companyPhone[0]"
                                :state="errors[0] ? false : (changed && valid ? true : null)"
                                placeholder="Номер телефона 1"
                            ></b-form-input>
                            <b-form-invalid-feedback>
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                    <div class="col-md-4">
                        <div v-if="!isCompanyPhone2" class="pt-2">
                            <button type="button" class="add-btn" @click="isCompanyPhone2 = true">
                                <b-icon icon="plus-circle-fill"></b-icon>
                                <span>Добавить номер телефона</span>
                            </button>
                        </div>
                        <div v-else class="extra-item">
                            <div class="extra-hide">
                                <button class="btn-crl" @click="handleHideCompanyPhone2">
                                    <b-icon icon="dash-circle-fill"></b-icon>
                                </button>
                            </div>
                            <div class="extra-control">
                                <ValidationProvider rules="phone|min:7|max:20" name="Телефон 2" tag="div">
                                    <div class="form-group" slot-scope="{ valid, errors, changed }">
                                        <b-form-input
                                            v-model="user.companyPhone[1]"
                                            :state="errors[0] ? false : (changed && valid ? true : null)"
                                            placeholder="Номер телефона 2"
                                        ></b-form-input>
                                        <b-form-invalid-feedback>
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mb-4">
                    <label>Профили в соц сетях (компания):</label>
                    <div class="row">
                        <ValidationProvider rules="min:5|link" name="Linkedin" tag="div" class="col-md-4">
                            <div class="form-group" slot-scope="{ valid, errors, changed }">
                                <b-form-input
                                    v-model="user.companyLinkedin"
                                    :state="errors[0] ? false : (changed && valid ? true : null)"
                                    placeholder="Ссылка на профиль Linkedin"
                                ></b-form-input>
                                <b-form-invalid-feedback>
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider rules="min:5|link" name="Facebook" tag="div" class="col-md-4">
                            <div class="form-group" slot-scope="{ valid, errors, changed }">
                                <b-form-input
                                    v-model="user.companyFacebook"
                                    :state="errors[0] ? false : (changed && valid ? true : null)"
                                    placeholder="Ссылка на профиль Facebook"
                                ></b-form-input>
                                <b-form-invalid-feedback>
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>

                <div>
                    <b-button type="submit" variant="primary submit pl-5 pr-5" :disabled="isBusy">
                        сохранить
                        <b-spinner v-if="isBusy" small type="grow"></b-spinner>
                    </b-button>
                </div>
            </b-form>
        </ValidationObserver>
    </div>
</template>

<script>
    import UploadPhoto from '@/components/forms/UploadPhoto';
    import { extend } from "vee-validate";
    import { errorsAlertApi } from '@/helpers/errorsAlertApi';
    import { mapActions, mapMutations } from 'vuex';

    extend("link", {
        validate(value) {
            const regex = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi);
            return value.match(regex)
        },
        message: field => 'Ссылка на профиль ' + field + ' должна начинаться с https://',
    });

    extend("phone", {
        validate(value) {
            const regex = new RegExp(/^\+[0-9]+$/);
            return value.match(regex)
        },
        message: field => `${field} должен начинаться с '+' и содержать только цифры`,
    });

    export default {
        name: "PersonalData",
        components: {
            UploadPhoto,
        },
        props: {
            userData: {
                type: Object
            },
        },
        computed: {
            getEditData() {
                const data = this.userData;
                this.userAvatar = data.avatarPath;
                for(let item in this.userData){
                    if(item === 'phone' && data.phone.length){
                        if(data.phone && data.phone.length < 2){
                            this.userPhone1 = data.phone[0];
                        } else {
                            this.isUserPhone2 = true;
                            this.userPhone1 = data.phone[0];
                            this.userPhone2 = data.phone[1];
                        }
                        continue;
                    }

                    if(item === 'companyPhone' && data[item].length > 1){
                        this.isCompanyPhone2 = true;
                    }

                    if(this.userData.hasOwnProperty(item) && this.user.hasOwnProperty(item)){
                        this.user[item] = data[item];
                    }

                    if(item === 'role'){
                        if(data[item] === 'ROOT'){
                            this.isShowSelectRole = false;
                        }
                        for(let idx in this.roles){
                            if(data[item] === this.roles[idx].role){
                                this.role = {
                                    title: this.roles[idx].title,
                                    role: this.roles[idx].role
                                };
                                break;
                            }
                        }
                    }
                }
            },
        },
        methods: {
            ...mapActions([
                'editUser',
                'saveUserAvatar',
                'addNewUser',
                'setUserRole'
            ]),
            ...mapMutations([
                'setUser'
            ]),
            handleHideUserPhone2(){
                this.isUserPhone2 = false;
                this.userPhone2.number = null;
                this.userPhone2.isPublic = false;
                if(this.user.phone.length > 1){
                    this.user.phone.splice(1, 1);
                }
            },
            handleHideCompanyPhone2(){
                this.isCompanyPhone2 = false;
                this.user.companyPhone.splice(1, 1);
            },
            async handleSubmit() {
                const isValid = await this.$refs.Personal.validate();
                if (isValid) {
                    this.isBusy = true;
                    if(this.userPhone1.number){
                        this.user.phone[0] = this.userPhone1;
                    }
                    if(this.userPhone2.number){
                        this.user.phone[1] = this.userPhone2;
                    }

                    const userId = this.userData._id;

                    // Редактируем данные пользователя
                    const data = {
                        userId,
                        user: this.user,
                        language: this.userData.languageCode ? this.userData.languageCode : 'ua'
                    };
                    this.editUser(data).then(() => {
                        // Добавляем аватрку пользователя
                        const editUserAvatarAction = new Promise(resolve => {
                            if(this.userAvatar && typeof this.userAvatar !== 'string'){
                                const userAvaData = {
                                    userId,
                                    avatar: this.userAvatar
                                };
                                this.saveUserAvatar(userAvaData).then(() => {
                                    resolve();
                                })
                                    .catch(error => {
                                        errorsAlertApi(error);
                                    })
                            } else {
                                resolve();
                            }
                        });

                        // Меняем Роль пользователя
                        const updatePremiumAction = new Promise(resolve => {
                            if(this.isUpdatedRole){
                                const data = {
                                    userId,
                                    role: this.role.role,
                                    language: this.userData.languageCode ? this.userData.languageCode : 'ua'
                                };
                                this.setUserRole(data).then(() => {
                                    resolve();
                                })
                                    .catch(error => {
                                        errorsAlertApi(error);
                                    })
                            } else {
                                resolve();
                            }
                        });

                        Promise.all([
                            editUserAvatarAction,
                            updatePremiumAction
                        ]).then(() => {
                            this.isBusy = false;
                            this.$swal({
                                icon: 'success',
                                text: 'Данные пользователя успешно изменены',
                                confirmButtonText: 'Ok'
                            });
                        });
                    })
                        .catch(error => {
                            errorsAlertApi(error);
                            this.isBusy = false;
                        });
                }
            }
        },
        mounted(){
            this.getEditData
        },
        data(){
            return{
                isBusy: false,
                userAvatar: null,
                userPhone1: {
                    isPublic: false,
                    number: null
                },
                userPhone2: {
                    isPublic: false,
                    number: null
                },
                isUserPhone2: false,
                isCompanyPhone2: false,
                isUpdatedRole: false,
                isShowSelectRole: true,
                role: null,
                roles: [
                    {
                        title: 'Юзер',
                        role: 'BASE'
                    }, {
                        title: 'Премиум',
                        role: 'PREMIUM'
                    }, {
                        title: 'Администратор',
                        role: 'ADMIN'
                    }
                ],
                user: {
                    firstName: '',
                    lastName: '',
                    patroName: '',
                    email: {
                        address: '',
                        isPublic: false
                    },
                    phone: [],
                    description: '',
                    companyType: null,
                    linkedin: '',
                    facebook: '',
                    companyName: '',
                    companyPosition: '',
                    companySite: '',
                    companyAddress: '',
                    companyEmail: [],
                    companyAbout: '',
                    companyPhone: [],
                    companyFacebook: '',
                    companyLinkedin: '',
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .avatar-box{
        padding-bottom: 10px;
    }
    .upload-photo{
        margin-bottom: 20px;
    }
    .mb{
        margin-bottom: 41px;
    }
    .switcher-item{
        font-size: 14px;
        color: #232323;
        display: flex;
        align-items: center;
        .custom-control{
            margin: 0 10px;
        }
        label{
            margin: 6px 0 0;
            cursor: pointer;
        }
    }
    .extra{
        &-item{
            display: flex;
        }
        &-hide{
            width: 26px;
            flex: none;
            padding-top: 7px;
            .bi{
                color: #f15959;
                font-size: 20px;
            }
            .btn-crl{
                padding: 0;
            }
        }
        &-control{
            flex: 1;
        }
    }
    .custom-radio{
        display: inline-block;
        margin: 5px 20px 5px 0;
    }
    .add-btn{
        padding: 0;
        .bi-plus-circle-fill{
            font-size: 20px;
        }
    }
    @media screen and (max-width: 1200px) {
        .mb{
            margin-bottom: 0;
        }
    }
</style>